import Vue from 'vue';
import Vuetify, {
	VRow,
	VCol,
	VTextField,
	VTooltip,
	VCheckbox,
	VSelect,
} from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import {Ripple, Intersect, Touch, Resize} from 'vuetify/lib/directives';

Vue.use(Vuetify, {
	components: {VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect},
	directives: {Ripple, Intersect, Touch, Resize},
});

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#01aff0',
				/*secondary: '#b38d4f',
				accent: '#cea25a',
				error: '#D32F2F',*/
			},
		},
	},
});
