import Vue from 'vue';
import Vuex from 'vuex';

import './projectConfig';
import App from './App.vue';

import * as dayjs from 'dayjs'
const timezone = require("dayjs/plugin/timezone");
const utc = require("dayjs/plugin/utc");
require('dayjs/locale/en');


import router, {removeRouteByName} from '@pazion/pazion-core/src/router';
import store from '@pazion/pazion-core/src/store/index';
import 'vue-material-design-icons/styles.css';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import { config } from 'vuex-module-decorators';

// Set rawError to true by default on all @Action decorators
config.rawError = true;

Vue.config.productionTip     = false;
//Vue.prototype.$orderListingType = 'combo';
Vue.prototype.$orderListingType = 'order';
Vue.prototype.$copyOrder = false;
Vue.prototype.$editOrder = true;
Vue.prototype.$orderDetails = true;
Vue.prototype.$createDelivery = false;
Vue.prototype.$showDeliveryDialog = false;
Vue.prototype.$editOrderLineStatus = false;

Vue.prototype.$stockManagement = false;
Vue.prototype.$productManagement = false;
Vue.prototype.$manufactureManagement = false;
Vue.prototype.$dossierManagement = false;
Vue.prototype.$orderDossierManagement = true;
Vue.prototype.$accountManagement = false;
Vue.prototype.$orderlineMeasureCalculation = false;
Vue.prototype.$orderQuickMenu = false;
Vue.prototype.$measureCalculationUnit = 'mm';
Vue.prototype.$orderAutoReference = true;

dayjs.extend(timezone);
dayjs.extend(utc)
dayjs.locale('en');
import './assets/scss/main.scss'

if( Vue.prototype.$stockManagement != true)
	removeRouteByName('stock');

if( Vue.prototype.$productManagement != true)
	removeRouteByName('products');

if( Vue.prototype.$dossierManagement != true)
	removeRouteByName('dossier');

if( Vue.prototype.$accountManagement != true)
	removeRouteByName('accounts');

Vue.use(Vuex);
Vue.use(VueRouter);
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

