import { addRoutes, addChildRoute, addRoute, redirectRouteToBackend, removeRouteByName} from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import {getCatalogRoutes} from "@pazion/pazion-catalog/src/module-config";
import About from "./views/About.vue";

addRoutes(getCatalogRoutes());

// addRoute(  {
//   path: '/testingoverride',
//   name: 'test',
//   component: About,
//   meta: {
//     'show-in-nav': true,
//     roles: ['user'],
//   },
// });
removeRouteByName('invoices');
removeRouteByName('returns');
removeRouteByName('stock');


addChildRoute("settings", {
	path: "/dynamics/settings",
	name: "dynamics",
	component: About,
	meta: {
		"show-in-nav": true,
		"redirect-to-backend": true,
		roles: ["user"]
	}
});


addChildRoute("settings", {
	path: "/woocommerce/settings",
	name: "woocommerce",
	component: About,
	meta: {
		"show-in-nav": true,
		roles: ["user"],
		sort: 800
	}
});
